import React, { useEffect, useState } from 'react';
import { ICategory } from '../../Interfaces/generalInterfaces';
import { useTranslation } from 'react-i18next';
export interface CategoryProp {
  categories: Array<ICategory>;
  onfilter: (category: ICategory) => void;
}
export const Categories: React.FC<CategoryProp> = ({
  categories,
  onfilter,
}) => {
  const { t }: { t: (key: string) => string } = useTranslation();

  const [activeCategory, setActiveCategory] = useState<number>(-1);
  useEffect(() => {
    if (categories && categories.length) {
      setActiveCategory(categories[0].id);
      onfilter(categories[0]); 
    }
  }, [categories]);

  const onFilterCategory = (category: ICategory) => {
    setActiveCategory(category.id);
    onfilter(category);
  };


  const newCategory = (): Array<ICategory> => {
 
    return categories;
  };


  return (
    <div className="sticky bg-white w-full top-16 z-20 pl-5 py-5 flex space-x-2 overflow-x-auto whitespace-nowrap">
      {newCategory() &&
        newCategory().map((item, key) => (
          <div
            key={item.id}
            onClick={() => onFilterCategory(item)}
            className={`${
              activeCategory === item.id
                ? 'bg-7m-bg-main text-white'
                : 'bg-7m-pink-10 text-black'
            } px-4 py-2 rounded-xl font-bold`}
          >
            {t(item.name)}
          </div>
        ))}
    </div>
  );
};
